.react-check-tree {
  position: relative;
}
.react-check-tree .tree-controller {
  background: #fff;
  color: #44444c;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 4px;
  min-height: 38px;
}
.react-check-tree .tree-controller.tree-opened {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-check-tree .tree-controller .tree-controller-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 37px);
  box-sizing: border-box;
  padding: 0 5px;
}
.react-check-tree .tree-controller .tree-controller-container .selected-choice {
  font-size: 12px;
  background: #a3a1a1;
  color: #fff;
  padding: 6px 5px;
  border-radius: 10px;
  margin: 3px;
  transition: all 0.2s ease;
  cursor: pointer;
}
.react-check-tree .tree-controller .tree-controller-container .selected-choice svg {
  margin-left: 5px;
  font-size: 10px;
}
.react-check-tree .tree-controller .tree-controller-container .selected-choice:hover svg {
  color: red;
}
.react-check-tree .tree-controller .tree-controller-container input {
  font-size: 16px;
  margin: 0;
  color: #44444c;
}
.react-check-tree .tree-controller .tree-controller-indicators {
  width: 36px;
  height: 100%;
  min-height: 20px;
  border-left: 1px solid #c7c7c7;
  color: #c7c7c7;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.react-check-tree .tree-root {
  position: absolute;
  top: 100%;
  left: 0;
  width: calc(100% - 2px);
  padding: 10px 5px;
  max-height: 400px;
  overflow-x: auto;
  z-index: 999;
  background: #fff;
  color: #44444c;
  border: 1px solid #bbb;
  box-sizing: border-box;
}
.react-check-tree .tree-choices {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.react-check-tree .tree-choices::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(137, 137, 137, 0.3);
}
.react-check-tree .tree-choices::-webkit-scrollbar {
  width: 15px;
}
.react-check-tree .tree-choices::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 50px;
}
.react-check-tree .tree-choices.tree-choices-hide {
  display: none !important;
}
.react-check-tree .tree-choices .tree-choice {
  margin-left: 15px;
  cursor: pointer;
}
.react-check-tree .tree-choices .tree-choice label {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none; /* Standard */
  font-weight: 300 !important;
}
.react-check-tree .tree-choices .tree-choice .show-hide-btn {
  margin-left: -15px;
}

body.dark-theme .react-check-tree .tree-controller {
  background: #454648;
  color: #ffffff;
}
body.dark-theme .react-check-tree .tree-root {
  background: #545454;
  color: #f7f7f7;
}
body.dark-theme .react-check-tree .tree-choices::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body.dark-theme .react-check-tree .tree-choices::-webkit-scrollbar {
  width: 15px;
}
body.dark-theme .react-check-tree .tree-choices::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 50px;
}/*# sourceMappingURL=style.css.map */