

.react-check-tree {
    position: relative;
    .tree-controller {
        background: #fff;
        color: #44444c;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        border-radius: 4px;
        min-height: 38px;

        &.tree-opened{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        
        .tree-controller-container{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: calc(100% - 37px);
            box-sizing: border-box;
            padding: 0 5px;

            .selected-choice {
                font-size: 12px;
                background: #a3a1a1;
                color: #fff;
                padding: 6px 5px;
                border-radius: 10px;
                margin: 3px;
                transition: all .2s ease;
                cursor: pointer;
    
                svg {
                    margin-left: 5px;
                    font-size: 10px;
                }
                &:hover {
                    svg {
                        color: red;
                    }
                }
            }

            input {
                font-size: 16px;
                margin: 0;
                color: #44444c;
            }
        }

        .tree-controller-indicators {
            width: 36px;
            height: 100%;
            min-height: 20px;
            border-left: 1px solid #c7c7c7;
            color: #c7c7c7;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        
    }
    .tree-root {
        position: absolute;
        top: 100%;
        left: 0;
        width: calc(100% - 2px);
        padding: 10px 5px;
        max-height: 400px;
        overflow-x: auto;
        z-index: 999;
        background: #fff;
        color: #44444c;
        border: 1px solid #bbb;
        box-sizing: border-box;
    }
    .tree-choices {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgb(137 137 137 / 30%);
        }
        &::-webkit-scrollbar {
            width: 15px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
            border-radius: 50px;
        }
        &.tree-choices-hide {
            display: none !important;
        }

        .tree-choice {
            margin-left: 15px;
            cursor: pointer;

            label {
                cursor: pointer;
                user-select: none; /* Standard */
                font-weight: 300 !important;
            }

            .show-hide-btn {
                margin-left: -15px;
            }
        }
    }
    
}

body.dark-theme {
    .react-check-tree {
        .tree-controller {
            background: #454648;
            color: #ffffff;
        }
        .tree-root {
            background: #545454;
            color: #f7f7f7;
        }
        .tree-choices {
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }
            &::-webkit-scrollbar {
                width: 15px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                outline: 1px solid slategrey;
                border-radius: 50px;
            }
        }
    }

}