.dark-theme {
  --main-background: #18191a;
  --light-background: #3a3b3d;
  --secondary-background: #1f232a;
  --modal-background: #415069;
  --modal-background-transparent: #232426;
  --link: #e7e7e7;
  --main-color: #eee;
  --secondary-color: #3d3d3d;
  --bs-body-color: #eee;
  --primary-btn-background: rgb(24, 25, 26);
  --primary-btn-background-hover: rgb(45, 27, 184);
  --primary-btn-color: #fff;
  --secondary-btn-background: rgb(107, 106, 110);
  --secondary-btn-color: #fff;
  --warning-btn-background: rgb(169, 160, 72);
  --warning-btn-color: #fff;
  --danger-btn-background: rgb(175, 39, 39);
  --danger-btn-color: #fff;
  --success-btn-background: rgb(46, 120, 21);
  --success-btn-color: #fff;
  --border-color: #cacaca;
  --shadow-color: #44434c;
}

:root {
  --main-background: #fff;
  --secondary-background: #f6f6f6;
  --light-background: #fdfdfd;
  --modal-background: #ffffff;
  --modal-background-transparent: #232426;
  --link: #1f232a;
  --main-color: #606060;
  --secondary-color: #eee;
  --primary-btn-background: rgb(87, 63, 225);
  --primary-btn-background-hover: rgb(122, 105, 217);
  --primary-btn-color: #fff;
  --secondary-btn-background: rgb(117, 116, 121);
  --secondary-btn-color: #fff;
  --warning-btn-background: rgb(215, 215, 105);
  --warning-btn-color: #fff;
  --danger-btn-background: rgb(171, 46, 46);
  --danger-btn-color: #fff;
  --success-btn-background: rgb(63, 225, 87);
  --success-btn-color: #fff;
  --border-color: #fff;
  --shadow-color: rgb(70, 69, 80);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  min-height: 100vh;
  background: var(--main-background);
  color: var(--main-color);
}

#root, .App, .content {
  min-height: 100vh;
}

.content {
  background: var(--main-background);
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.mb-6 {
  margin-bottom: 6rem !important;
}

.mb-7 {
  margin-bottom: 7rem !important;
}

.mb-8 {
  margin-bottom: 8rem !important;
}

.spinning {
  animation-name: spin;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.transparent {
  background: transparent !important;
}

.hidden {
  display: none !important;
}

.header {
  overflow: hidden;
  padding: 20px 10px;
  background-color: var(--secondary-background);
}
.header a {
  float: left;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
  transition: all 0.2s ease;
  color: var(--link);
}
.header a:hover {
  background-color: var(--primary-btn-background-hover);
}
.header a.active {
  background-color: var(--primary-btn-background-hover);
}
.header a.logo {
  font-size: 25px;
  font-weight: bold;
}

.header-right {
  float: right;
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: center;
  }
  .header-right {
    float: none;
  }
}
.primary-background {
  background: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.dropdown-menu {
  background-color: var(--light-background) !important;
}

.bg-dark {
  background-color: #232426 !important;
}

.round {
  border-radius: 50px;
}

.btn {
  margin: 0.2rem;
  border: none;
  cursor: pointer;
}

.message {
  text-align: center;
  padding: 10px 20px;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 0 --shadow-color;
}
.message.success-message {
  background: rgb(27, 179, 34);
}
.message.warning-message {
  background: rgb(230, 216, 20);
}
.message.danger-message {
  background: rgb(202, 40, 29);
}
.message.primary-message {
  background: rgb(29, 61, 202);
}
.message.secondary-message {
  background: rgb(153, 153, 153);
}
.message h1 {
  font-size: 18px;
}

.announcements-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}
.announcements-wrapper.box {
  row-gap: unset;
}
.announcements-wrapper.box .announcement-wrapper {
  width: calc(50% - 20px);
  margin: 10px;
  border: none !important;
  border-radius: 7px;
}
@media screen and (max-width: 767px) {
  .announcements-wrapper.box .announcement-wrapper {
    width: calc(100% - 20px);
  }
}

.announcement-wrapper-skeleton {
  min-height: 270px;
}
.announcement-wrapper-skeleton h5 {
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  min-height: 30px;
  border-radius: 50px;
}
.announcement-wrapper-skeleton .badges .tag-badge {
  min-width: 40px;
  min-height: 20px;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.announcement-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  text-align: center;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  background: var(--light-background);
  border-radius: 7px;
}
.announcement-wrapper .pinned-icon {
  transform: rotate(45deg);
  position: absolute;
  top: -5px;
  right: -6px;
  font-size: 20px;
}
.announcement-wrapper .post-date {
  font-size: 12px;
  text-align: right;
  display: block;
  width: 100%;
}
.announcement-wrapper .announcement-header {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
}
.announcement-wrapper .announcement-header a {
  color: unset;
  text-decoration: none;
}
.announcement-wrapper .announcement-header h5 {
  font-weight: 600;
}
.announcement-wrapper .badges {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 5px;
       column-gap: 5px;
  row-gap: 5px;
}
.announcement-wrapper .badges .tag-badge {
  padding: 4px 6px;
  border-radius: 7px;
  font-size: 14px;
  color: #effaf5;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.announcement-wrapper .summary {
  text-align: left;
  color: #c5c5c5;
  padding-bottom: 10px 0;
  word-wrap: break-word;
  max-width: 100%;
}
.announcement-wrapper .announcement-footer {
  height: -moz-max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.announcement-wrapper .announcement-meta {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #858585;
  padding-bottom: 5px;
}
.announcement-wrapper .announcement-meta span {
  width: 50%;
}
.announcement-wrapper .announcement-meta .author {
  text-align: left;
  font-size: 12px;
  cursor: pointer;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background: var(--modal-background-transparent);
}
.pagination-wrapper div {
  padding: 10px;
  margin: 5px;
  color: var(--secondary-btn-color);
  background: var(--secondary-btn-background);
  cursor: pointer;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}
.pagination-wrapper div.active {
  background: var(--primary-btn-background);
}

.justify-center {
  justify-content: center;
}

.search-params-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3764705882);
  z-index: 100;
  display: none;
}
.search-params-container .search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-params-container.show {
  display: flex;
}
.search-params-container.show .search-params-wrapper {
  left: 0;
}
.search-params-container .search-params-wrapper {
  position: absolute;
  left: -100vh;
  transition: all 0.2s ease;
  background: #3a3b3d;
  padding: 10px 20px;
  min-width: 300px;
  max-width: 450px;
  height: 100vh;
}
.search-params-container .search-params-wrapper .close-search-params {
  cursor: pointer;
}
.search-params-container .search-params-wrapper .close-search-params:hover {
  color: red;
}

.search-btn {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  min-width: 110px;
}

.main-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  align-items: center;
}
.main-header .view-options span {
  cursor: pointer;
  margin-left: 5px;
  padding: 5px 10px;
  font-size: 18px;
  border: 2px solid transparent;
}
.main-header .view-options span.active {
  border: 2px solid var(--border-color);
  border-radius: 10px;
}

.no-announcements-found {
  margin: auto;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  color: var(--main-color);
}

.form-group {
  width: 100%;
}

.form-control {
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
}
.form-control > label {
  font-size: 14px;
  display: block;
}
.form-control input:not([type=checkbox]) {
  border: none;
  background: none;
  border-bottom: 1px solid var(--main-color);
  font-size: 14px;
  padding: 5px;
  margin-top: 10px;
  width: 100%;
}
.form-control input:not([type=checkbox]):focus-visible {
  border: none;
  border-bottom: 1px solid var(--main-color);
  outline: none;
}
.form-control.form-control-xl {
  display: flex;
  flex-direction: column;
}
.form-control.form-control-xl label {
  font-size: 18px;
  font-weight: 600;
}
.form-control.form-control-xl input {
  font-size: 18px;
}

.checkbox-wrapper label {
  cursor: pointer;
  margin-left: 1rem;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.file {
  background: rgba(13, 110, 253, 0.6588235294);
  margin: 8px;
  padding: 5px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  cursor: pointer;
}
.file:hover svg {
  color: red;
}
.file span {
  margin: 5px;
  word-break: break-word;
}

.user-nav {
  color: var(--main-color) !important;
}
.user-nav.active {
  color: var(--secondary-color) !important;
}

.chosen-tags {
  display: flex;
  flex-wrap: wrap;
}
.chosen-tags .chosen-tag {
  background: var(--light-background);
  padding: 5px 10px;
  border-radius: 10px;
  margin: 5px;
}

.full-announcement-wrapper {
  margin-top: 50px;
}
.full-announcement-wrapper .announcement-header {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border-color);
}
.full-announcement-wrapper .announcement-header h1 {
  font-size: 2rem !important;
}
.full-announcement-wrapper .announcement-header .post-date {
  text-align: right;
  display: block;
}
.full-announcement-wrapper .summary {
  padding-top: 10px;
  text-align: justify;
  word-wrap: break-word;
  max-width: 100%;
}
.full-announcement-wrapper .announcement-footer {
  text-align: right;
}
.full-announcement-wrapper .badges {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 5px;
       column-gap: 5px;
  row-gap: 5px;
}
.full-announcement-wrapper .badges .tag-badge {
  padding: 4px 6px;
  border-radius: 7px;
  font-size: 14px;
  color: #effaf5;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.full-announcement-wrapper .files {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.full-announcement-wrapper .files .file {
  font-size: 13px;
  padding: 2px 6px !important;
  margin: 4px !important;
}

body.light-theme .navbar {
  background-color: #ebebeb;
}
body.light-theme .pagination-wrapper {
  background: #f5f5f5;
}
body.light-theme .pagination-wrapper div {
  background: rgb(185, 181, 197);
}
body.light-theme .pagination-wrapper div.active {
  background: hsl(212.86, 100%, 60.98%);
}
body.light-theme .badges .tag-badge {
  background: hsl(207.69, 100%, 89.01%);
  color: #3892ff;
}
body.light-theme .announcement-wrapper {
  box-shadow: -1px -2px 13px #dfdede;
}
body.light-theme .announcement-wrapper .announcement-meta {
  border-bottom: 1px solid #f1f1f1;
}
body.light-theme .announcement-wrapper .summary {
  color: #363636;
}
body.light-theme .form-control input:not([type=checkbox]) {
  border-bottom: 1px solid #d5d5d5;
}
body.light-theme .user-nav {
  color: #b7b7b7 !important;
}
body.light-theme .user-nav.active {
  color: #0e0e0e !important;
}
body.light-theme .announcement-wrapper-skeleton h5 {
  background: rgba(86, 86, 86, 0.1);
}
body.light-theme .main-header .view-options span.active {
  border: 2px solid #b9b9b9;
}
body.light-theme .search-params-wrapper {
  background: #fff;
}
body.light-theme .btn-secondary {
  background: #6da6d7;
}
body.light-theme .btn-secondary:hover {
  background: #298ee5;
}
body.light-theme .btn-secondary:focus {
  background: #298ee5;
}
body.light-theme .btn-secondary:focus-within {
  background: #298ee5;
}
body.light-theme .btn-secondary:active {
  background: #298ee5;
}

body.dark-theme .form-control {
  background-color: #454648;
  border: 1px solid #454648;
  color: #ffffff;
}
body.dark-theme .form-control input:not([type=checkbox]) {
  color: #fff;
  border-bottom: 1px solid #545454;
}
body.dark-theme .my-react-select__control, body.dark-theme .my-react-select__menu {
  background: #454648;
  border-color: #454648;
  color: #fff;
}
body.dark-theme .my-react-select__single-value {
  color: #fff;
}
body.dark-theme .my-react-select__option--is-focused {
  background: #818181;
}
body.dark-theme .my-react-select__input-container, body.dark-theme .my-react-select__input {
  color: #fff;
}/*# sourceMappingURL=main.css.map */